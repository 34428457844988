.partners-pic {
  font-family: "Unbounded", cursive;

  background-image: url(../../images/partners.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;

  padding-top: 5px;
  padding-bottom: 5px;

  width: 100%;
  margin: auto;

  height: 70vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .partners-title {
    font-size: 3rem;
    color: rgb(249, 246, 246);
  }

  .partnerships-title {
    font-family: "Unbounded", cursive;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 2.5em;
    margin-bottom: 1em;

    font-size: 2em;
  }

  .interested {
    font-family: "Unbounded", cursive;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 2.5em;
    margin-bottom: 1em;

    font-size: 1em;
  }

  .interested-text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;

    padding: 1em 1em 1em 1em;

    margin-bottom: 1em;
  }
}

@media screen and (min-width: 601px) {
  .partners-title {
    font-family: "Unbounded", cursive;

    font-size: 7.5em;
    color: rgb(247, 246, 246);
  }
  .partnerships-title {
    font-family: "Unbounded", cursive;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 2.5em;
    margin-bottom: 1em;

    font-size: 2em;
  }

  .regular-picture {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo1 {
    background-image: url(../../images/partners.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;

    width: 100%;
    margin: auto;

    height: 35vh;
  }

  .interested {
    font-family: "Unbounded", cursive;

    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    max-width: 750px;
    width: 100%;

    margin-top: 2.5em;
    margin-bottom: 1em;

    font-size: 1em;
  }

  .interested-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    padding: 1em 1em 1em 1em;
    margin-bottom: 1em;

    font-size: 1em;
    text-align: left;
  }
}
