@media screen and (max-width: 600px) {
  .exercises {
    font-family: "Unbounded", cursive;
    font-size: 3em;
    color: white;

    background-image: url(../../images/groupexercises.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;

    height: 55vh;
    width: 100%;
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .exercise-list {
    font-family: "Unbounded", cursive;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 55vh;
  }
}

@media screen and (min-width: 601px) {
  .exercises {
    font-family: "Unbounded", cursive;
    font-size: 5em;
    color: white;

    background-image: url(../../images/groupexercises.jpg);
    background-size: cover;
    background-position: center;

    height: 55vh;
    width: 100%;
    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .exercise-row {
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 450px;
    min-width: 200px;
    max-width: 100%;
    max-height: 100%;

    padding: 1em 1em 1em 1em;
  }

  .exercise {
    padding: 1em;
    margin: 1em;

    min-width: 450px;
    min-width: 200px;
    max-width: 100%;
    max-height: 100%;
  }
}
