@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@500&display=swap");

.meet-the-team {
  font-family: "Unbounded", cursive;
  font-size: 50px;
  color: white;

  background-image: url(../../images/meetplease.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;

  height: 55vh;
  width: 100%;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.meet-the-team-title {
  font-family: "Unbounded", cursive;
  font-size: 150px;
  color: white;
}

.exec-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team-rows {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 100px;
  padding: 20px 0px 20px 0px;
}

.MuiCardMedia-img {
  height: 100%;
}

@media screen and (max-width: 600px) {
  .team-rows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 4em;
    padding: 1em 1em 1em 1em;
  }

  .meet-the-team {
    font-family: "Unbounded", cursive;
    font-size: 2.5em;
    color: white;
  }
}
