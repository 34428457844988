@media screen and (max-width: 700px) {
  .news {
    font-family: "Unbounded", cursive;
    font-size: 3em;
    color: white;

    background-image: url(../../images/news.jpg);
    background-size: cover;
    background-position: cover;
    background-position: 75% 75%;
    background-attachment: fixed;
    background-repeat: no-repeat;

    height: 55vh;
    width: 100%;
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .news-list {
    display: flex;
    flex-direction: column;
    padding-top: 5em;

    max-width: 700px;
    width: 100%;
    margin: auto;
  }

  .news-title {
    display: flex;
    align-items: center;
    margin: 1em 1em 1em 1em;

    text-align: left;

    font-size: 2.5em;

    font-family: "Unbounded", cursive;
  }

  .news-title-story-1 {
    display: flex;
    align-items: center;
    margin: auto;

    margin-top: 1em;

    font-size: 1em;
    line-height: 25px;
    font-family: "Unbounded", cursive;
  }

  .news-image {
    display: none;
  }

  .news-mobile-image {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 1em 1em 1em 1em;
  }

  .news-story-desc {
    text-align: left;
    line-height: 25px;

    margin: 1em 1em 2em 1em;
  }

  .news-story-1 {
    margin: 1em 1em 2em 1em;
  }

  .learn-more-button {
    display: flex;

    margin: 1em 1em 2em 1em;
  }

  .news-title-story-2 {
    font-family: "Unbounded", cursive;
    font-size: 0.9em;
  }

  .news-image-3 {
    display: none;
  }
}

@media screen and (min-width: 701px) {
  .news {
    font-family: "Unbounded", cursive;
    font-size: 5em;
    color: white;

    background-image: url(../../images/news.jpg);
    background-size: cover;
    background-position: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;

    height: 55vh;
    width: 100%;
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .news-list {
    display: flex;
    flex-direction: column;
    padding-top: 5em;

    max-width: 700px;
    width: 100%;
    margin: auto;
  }

  .news-mobile-image {
    display: none;
  }

  .news-title {
    display: flex;
    align-items: center;
    margin: auto;

    text-align: left;

    font-size: 2.5em;

    font-family: "Unbounded", cursive;
  }

  .news-title-story-1 {
    display: flex;
    align-items: center;
    margin: auto;

    margin-top: 1em;

    font-size: 1em;
    line-height: 25px;
    font-family: "Unbounded", cursive;
  }

  .news-image {
    display: flex;
    align-items: center;
    justify-content: center;

    object-fit: cover;
    margin: 1em 1em 1em 1em;
  }

  .news-story-desc {
    text-align: left;
    line-height: 25px;

    margin: 1em 1em 2em 1em;
  }

  .news-story-1 {
    margin: 1em 1em 2em 1em;
  }

  .learn-more-button {
    display: flex;

    margin: 1em 1em 2em 1em;
  }

  .news-title-story-2 {
    font-family: "Unbounded", cursive;
    font-size: 0.9em;
  }

  .news-story-2 {
    margin: 1em 1em 1em 1em;
  }

  .news-image-3 {
    display: flex;
    align-items: center;
    justify-content: center;

    object-fit: cover;
    margin: 1em 1em 1em 1em;
  }
}
