@media screen and (max-width: 700px) {
  .donate {
    background-image: url(../../images/getinvolved.jpg);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 55vh;

    font-size: 3em;
    color: white;
    font-family: "Unbounded", cursive;
  }

  .current-needs-title {
    font-size: 1.1em;
    font-family: "Unbounded", cursive;
    margin: 1em 1em 0em 1em;
  }

  .current-needs-list {
    display: flex;
    align-items: center;
    justify-content: center;

    vertical-align: middle;
    box-sizing: border-box;

    width: auto;
    margin: auto;
    max-width: 800px;
    padding: 0px 0px;

    font-size: 15px;
    line-height: 30px;
    width: 90%;
    text-align: left;
  }

  .contact-farm-2-clinic {
    font-size: 1.5em;
    font-family: "Unbounded", cursive;
    margin: 1em 1em 2em 1em;
  }

  .form-for-contact {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 200px;
    height: 50vh;

    margin: auto;
    margin-bottom: 5em;
    position: inherit;
    padding: 3vw;
  }

  .form-for-contact input,
  textarea {
    width: 90%;
    padding: 1rem 1rem 1rem 1rem;
    margin: 1vw 0;
    border: 0;
    border-radius: 5px;
    font-size: 20px;
  }

  .form-for-contact textarea {
    height: 15vh;
    min-width: 275px;
    max-width: 275px;
    max-height: 100px;

    border-color: black;
  }

  .form-for-contact label {
    display: block;
    text-align: center;
    font-size: 20px;
  }

  .follow-us-title {
    margin-top: 1em;
    margin-bottom: 1em;

    font-size: 1.25em;
    font-family: "Unbounded", cursive;
  }

  .follow-us-section {
    height: 35vh;

    margin-top: 2em;
    margin-bottom: 25em;
  }

  .social-media-rows {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: 2em;

    row-gap: 1.75em;
  }

  .social-media img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .social-media {
    width: 100px;
  }

  .map-container {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: auto;
    margin-bottom: 3em;

    max-width: 500px;
    width: 100%;

    height: 55vh;
  }

  .container-map {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: auto;
    width: 90%;

    height: 60vh;
    margin-top: 3em;
  }
}

@media screen and (min-width: 701px) {
  .donate {
    background-image: url(../../images/getinvolved.jpg);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 55vh;

    font-size: 7.5em;
    color: white;
    font-family: "Unbounded", cursive;
  }

  .current-needs-title {
    font-size: 1.5em;
    font-family: "Unbounded", cursive;
    margin: 1em 1em 0em 1em;
  }

  .current-needs-list {
    display: flex;
    align-items: center;
    justify-content: center;

    vertical-align: middle;
    box-sizing: border-box;

    width: auto;
    margin: auto;
    max-width: 800px;
    padding: 0px 0px;

    font-size: 15px;
    line-height: 30px;
    width: 90%;
    text-align: left;
  }

  .contact-farm-2-clinic {
    font-size: 1.5em;
    font-family: "Unbounded", cursive;
    margin: 1em 1em 0em 1em;
  }

  .form-for-contact {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 750px;
    height: 50vh;

    margin: auto;
    position: inherit;
    padding: 3vw;
  }

  .form-for-contact input,
  textarea {
    width: 90%;
    padding: 1rem 1rem 1rem 1rem;
    margin: 1vw 0;
    border: 0;
    border-radius: 5px;
    font-size: 20px;
  }

  .form-for-contact textarea {
    height: 15vh;

    min-width: 700px;
    max-width: 700px;
    max-height: 100px;

    border-width: 1px;
    border-style: solid;
    border-color: black;
  }

  .form-for-contact label {
    display: block;
    text-align: left;
    font-size: 20px;
  }

  .form-for-contact input {
    border-width: 1px;
    border-style: solid;
    border-color: black;
  }

  .follow-us-title {
    margin-top: 1em;
    margin-bottom: 1em;

    font-size: 2em;
    font-family: "Unbounded", cursive;
  }

  .follow-us-section {
    height: 50vh;

    margin-top: 2em;
    margin-bottom: 10em;
  }

  .social-media-rows {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 2em;

    column-gap: 5em;
  }

  .social-media img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .social-media {
    width: 200px;
  }

  .map-container {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: auto;
    margin-bottom: 3em;

    max-width: 500px;
    width: 100%;

    height: 55vh;
  }
}
