.farm-2-clinic {
  width: auto;
  height: 150px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #1c1c1c;
}

.farm-2-clinic-text {
  color: white;
}

.credits {
  margin-top: 1em;

  color: white;
}
