@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@500&display=swap");

.nav {
  padding-right: 64px;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
}

@media screen and (max-width: 600px) {
  .section-2 {
    font-family: "Unbounded", cursive;
    font-size: 3em;
    color: white;

    background-image: url(../../images/farm2clinic.jpeg);
    background-size: cover;
    background-position: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;

    height: 55vh;
    width: 100%;
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rows {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .our-mission-title {
    font-family: "Unbounded", cursive;

    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    margin-top: 50px;

    font-size: 1em;
  }

  .our-mission-text {
    text-align: left;

    font-size: 15px;
    line-height: 25px;

    margin: 1.5em 1.5em 1.5em 1.5em;
    width: 80%;
  }

  .main-content {
    vertical-align: middle;

    box-sizing: border-box;

    width: auto;
    margin: auto;
    max-width: 1020px;
    padding-bottom: 55px;
  }

  .logo-image {
    width: 75%;
  }

  .our-work-title {
    font-family: "Unbounded", cursive;

    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    padding-bottom: 1.5em;

    font-size: 1em;
  }

  .our-work-section {
    box-sizing: border-box;

    width: 80%;
    margin: auto;
  }

  .our-work-text {
    text-align: left;

    font-size: 15px;
    line-height: 25px;

    margin: 1.5em 1.5em 1.5em 1.5em;
    width: 80%;
  }

  .our-work-list {
    font-size: 15px;
    line-height: 30px;
    width: 90%;
  }

  .where-we-are-section {
    box-sizing: border-box;

    width: 80%;
    margin: auto;

    padding-bottom: 2em;
  }

  .theoretical-framework-section {
    box-sizing: border-box;

    width: 80%;
    margin: auto;
  }

  .where-we-are {
    font-family: "Unbounded", cursive;

    box-sizing: border-box;

    font-size: 1em;

    margin: auto;

    width: 100%;
  }

  .theoretical-framework {
    display: none;
  }

  .map-content {
    vertical-align: middle;

    box-sizing: border-box;

    width: auto;
    margin: auto;
    /* max-width: 1020px;
    padding-top: 0px 32px;
    padding-bottom: 5px; */
  }

  .map-farm2clinic {
    display: none;
  }
}

@media screen and (min-width: 601px) {
  .section-2 {
    font-family: "Unbounded", cursive;
    font-size: 5em;
    color: white;

    background-image: url(../../images/farm2clinic.jpeg);
    background-size: cover;
    background-position: cover;

    height: 55vh;
    width: 100%;
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rows {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .our-mission-title {
    font-family: "Unbounded", cursive;

    box-sizing: border-box;

    padding-top: 50px;
    padding-left: 130px;
    text-align: left;

    font-size: 1em;
  }

  .main-content {
    vertical-align: middle;

    box-sizing: border-box;

    width: auto;
    margin: auto;
    max-width: 1020px;
    padding-top: 0px 32px;
    padding-bottom: 45px;
  }

  .our-mission-text {
    text-align: left;

    font-size: 15px;
    line-height: 25px;

    width: 35%;
    padding-right: 42.5px;
  }

  .logo-image {
    width: 25%;
  }

  .our-work-title {
    font-family: "Unbounded", cursive;

    box-sizing: border-box;

    text-align: left;
    padding-left: 100px;

    font-size: 1em;
  }

  .our-work-section {
    vertical-align: middle;

    box-sizing: border-box;

    width: auto;
    margin: auto;
    max-width: 1020px;
    padding: 0px 32px;
  }

  .our-work-text {
    text-align: left;

    font-size: 15px;
    line-height: 25px;

    padding-left: 100px;

    width: 80%;
  }

  .our-work-list {
    font-size: 15px;
    line-height: 30px;
    width: 90%;
  }

  .where-we-are-section {
    vertical-align: middle;

    box-sizing: border-box;

    width: auto;
    margin: auto;
    max-width: 1020px;
    padding: 0px 32px;
  }

  .where-we-are {
    font-family: "Unbounded", cursive;

    box-sizing: border-box;

    text-align: left;
    font-size: 1em;

    padding-left: 100px;

    width: 100%;
  }

  .theoretical-framework {
    vertical-align: middle;

    box-sizing: border-box;

    width: auto;
    margin: auto;
    margin-bottom: 40em;

    height: 15vh;
  }

  .theoretical-framework-title {
    font-family: "Unbounded", cursive;

    box-sizing: border-box;

    text-align: left;
    font-size: 1em;

    padding-left: 100px;
    padding-bottom: 2.5em;

    width: 100%;
  }

  .theoretical-framework-section {
    vertical-align: middle;

    box-sizing: border-box;

    width: auto;
    margin: auto;
    max-width: 1020px;
    padding: 0px 32px;
  }

  .map-content {
    vertical-align: middle;

    box-sizing: border-box;

    width: auto;
    margin: auto;
    max-width: 1020px;
    padding-top: 0px 32px;
    padding-bottom: 5px;
  }

  .map-farm2clinic {
    vertical-align: middle;

    box-sizing: border-box;

    width: auto;
    margin: auto;
    margin-bottom: 45em;

    height: 15vh;
  }
}
