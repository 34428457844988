@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@500&display=swap");

.intro {
  padding-bottom: 32px;
}

.recipe-pic {
  font-family: "Unbounded", cursive;

  padding-top: 5px;
  padding-bottom: 5px;

  width: 100%;
  margin: auto;

  height: 70vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url(../../images/recipeImage2.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

@media screen and (max-width: 600px) {
  .recipes-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 100px 100px 100px 100px;
  }

  .recipes-mobile-desc {
    font-family: "Unbounded", cursive;
    font-size: 20px;
    margin-bottom: 45px;
  }

  .recipe-links-not-mobile {
    display: none;
  }

  .recipe-pic {
    height: 35vh;
  }

  .section-1-title {
    padding-top: 200px;
    font-size: 7.5rem;
    color: white;
  }

  .recipe-title {
    font-size: 3.5rem;
    color: white;
  }

  .recipe-whole {
    width: 100%;
  }

  .carousel {
    display: none;
  }

  .cabbage {
    display: none;
  }

  .cantaloupe {
    display: none;
  }

  .carrot {
    display: none;
  }

  .cauliflower {
    display: none;
  }

  .collard {
    display: none;
  }

  .cucumber {
    display: none;
  }

  .green-bean {
    display: none;
  }

  .kale {
    display: none;
  }

  .okra {
    display: none;
  }

  .spinach {
    display: none;
  }

  .squash {
    display: none;
  }

  .sweet {
    display: none;
  }

  .turnip {
    display: none;
  }

  .white {
    display: none;
  }
}

@media screen and (min-width: 601px) {
  .produce {
    padding-bottom: 4em;
  }

  .first-row {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1em 1em 1em 1em;
  }

  .one {
    padding: 1em 1em 1em 1em;
    margin: 1em;

    max-width: 450px;
    min-width: 200px;
    width: auto;

    height: auto;
  }

  .second-row {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    padding: 1em 1em 1em 1em;
  }

  .third-row {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    padding: 1em 1em 1em 1em;
  }

  .fourth-row {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    padding: 1em 1em 1em 1em;
  }

  .fifth-row {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    padding: 1em 1em 1em 1em;
  }

  .recipes-mobile {
    display: none;
  }

  .section-1-title {
    padding-top: 200px;
    font-size: 7.5rem;
    color: white;
  }

  .recipe-title {
    font-size: 150px;
    color: white;
  }

  .recipe-whole {
    width: 100%;
  }

  .carousel {
    display: flex;
    flex-direction: column;

    vertical-align: middle;

    width: auto;
    height: 1450px;
    margin: auto;
    max-width: 1000px;
    padding: 32px;
    padding-bottom: 2px;

    background-color: black;

    margin-top: 50px;
  }

  .carousel-produce {
    display: flex;
    flex-direction: column;

    vertical-align: middle;

    width: auto;
    height: 1500px;
    margin: auto;
    max-width: 1100px;

    padding-top: 5em;
    padding-bottom: 5em;

    /* background-color: black; */
  }

  .carousel-produce-blueberries {
    display: flex;
    flex-direction: column;

    vertical-align: middle;

    width: auto;
    height: 1500px;
    margin: auto;
    max-width: 1100px;

    padding-top: 5em;
    padding-bottom: 5em;

    /* background-color: black; */
  }

  .carouselInner-produce {
    height: 140vh;
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }

  .carouselInner {
    height: 140vh;
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }

  .carouselInner .left {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .carouselInner .center {
    flex: 95%;
    height: 100%;
  }

  .carouselInner .right {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .cabbage {
    display: flex;
    flex-direction: column;

    vertical-align: middle;

    width: auto;
    height: 1450px;
    margin: auto;
    max-width: 1000px;
    padding: 32px;
    padding-bottom: 2px;

    background-color: black;

    margin-top: 50px;
  }

  .cabbageInner {
    height: 140vh;
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }

  .cabbageInner .left {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .cabbageInner .center {
    flex: 95%;
    height: 100%;
  }

  .cabbageInner .right {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .cantaloupe {
    display: flex;
    flex-direction: column;
    vertical-align: middle;

    width: auto;
    max-width: 1000px;
    margin: auto;
    height: 1450px;

    padding: 32px;
    margin-top: 50px;

    background-color: black;
  }

  .cantaloupeInner {
    height: 140vh;
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }

  .cantaloupeInner .left {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .cantaloupeInner .center {
    flex: 95%;
    height: 100%;
  }

  .cantaloupeInner .right {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .carrot {
    display: flex;
    flex-direction: column;
    vertical-align: middle;

    width: auto;
    max-width: 1000px;
    margin: auto;
    height: 1450px;

    padding: 32px;
    margin-top: 50px;

    background-color: black;
  }

  .carrotInner {
    height: 140vh;
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }

  .carrotInner .left {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .carrotInner .center {
    flex: 95%;
    height: 100%;
  }

  .carrotInner .right {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .cauliflower {
    display: flex;
    flex-direction: column;
    vertical-align: middle;

    width: auto;
    max-width: 1000px;
    margin: auto;
    height: 1450px;

    padding: 32px;
    margin-top: 50px;

    background-color: black;
  }

  .cauliflowerInner {
    height: 140vh;
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }

  .cauliflowerInner .left {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .cauliflowerInner .center {
    flex: 95%;
    height: 100%;
  }

  .cauliflowerInner .right {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .collard {
    display: flex;
    flex-direction: column;
    vertical-align: middle;

    width: auto;
    max-width: 1000px;
    margin: auto;
    height: 1450px;

    padding: 32px;
    margin-top: 50px;

    background-color: black;
  }

  .collardInner {
    height: 140vh;
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }

  .collardInner .left {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .collardInner .center {
    flex: 95%;
    height: 100%;
  }

  .collardInner .right {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .cucumber {
    display: flex;
    flex-direction: column;
    vertical-align: middle;

    width: auto;
    max-width: 1000px;
    margin: auto;
    height: 1450px;

    padding: 32px;
    margin-top: 50px;

    background-color: black;
  }

  .cucumberInner {
    height: 140vh;
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }

  .cucumberInner .left {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .cucumberInner .center {
    flex: 95%;
    height: 100%;
  }

  .cucumberInner .right {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .green-bean {
    display: flex;
    flex-direction: column;
    vertical-align: middle;

    width: auto;
    max-width: 1000px;
    margin: auto;
    height: 1450px;

    padding: 32px;
    margin-top: 50px;

    background-color: black;
  }

  .greenBeanInner {
    height: 140vh;
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }

  .greenBeanInner .left {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .greenBeanInner .center {
    flex: 95%;
    height: 100%;
  }

  .greenBeanInner .right {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .kale {
    display: flex;
    flex-direction: column;
    vertical-align: middle;

    width: auto;
    max-width: 1000px;
    margin: auto;
    height: 1450px;

    padding: 32px;
    margin-top: 50px;

    background-color: black;
  }

  .kaleInner {
    height: 140vh;
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }

  .kaleInner .left {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .kaleInner .center {
    flex: 95%;
    height: 100%;
  }

  .kaleInner .right {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .okra {
    display: flex;
    flex-direction: column;
    vertical-align: middle;

    width: auto;
    max-width: 1000px;
    margin: auto;
    height: 1450px;

    padding: 32px;
    margin-top: 50px;

    background-color: black;
  }

  .okraInner {
    height: 140vh;
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }

  .okraInner .left {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .okraInner .center {
    flex: 95%;
    height: 100%;
  }

  .okraInner .right {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .spinach {
    display: flex;
    flex-direction: column;
    vertical-align: middle;

    width: auto;
    max-width: 1000px;
    margin: auto;
    height: 1450px;

    padding: 32px;
    margin-top: 50px;

    background-color: black;
  }

  .spinachInner {
    height: 140vh;
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }

  .spinachInner .left {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .spinachInner .center {
    flex: 95%;
    height: 100%;
  }

  .spinachInner .right {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .squash {
    display: flex;
    flex-direction: column;
    vertical-align: middle;

    width: auto;
    max-width: 1000px;
    margin: auto;
    height: 1450px;

    padding: 32px;
    margin-top: 50px;

    background-color: black;
  }

  .squashInner {
    height: 140vh;
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }

  .squashInner .left {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .squashInner .center {
    flex: 95%;
    height: 100%;
  }

  .squashInner .right {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .sweet {
    display: flex;
    flex-direction: column;
    vertical-align: middle;

    width: auto;
    max-width: 1000px;
    margin: auto;
    height: 1450px;

    padding: 32px;
    margin-top: 50px;

    background-color: black;
  }

  .sweetInner {
    height: 140vh;
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }

  .sweetInner .left {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .sweetInner .center {
    flex: 95%;
    height: 100%;
  }

  .sweetInner .right {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .turnip {
    display: flex;
    flex-direction: column;
    vertical-align: middle;

    width: auto;
    max-width: 1000px;
    margin: auto;
    height: 1450px;

    padding: 32px;
    margin-top: 50px;

    background-color: black;
  }

  .turnipInner {
    height: 140vh;
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }

  .turnipInner .left {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .turnipInner .center {
    flex: 95%;
    height: 100%;
  }

  .turnipInner .right {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .white {
    display: flex;
    flex-direction: column;
    vertical-align: middle;

    width: auto;
    max-width: 1000px;
    margin: auto;
    height: 1450px;

    padding: 32px;
    margin-top: 50px;

    background-color: black;

    margin-bottom: 5em;
  }

  .whiteInner {
    height: 140vh;
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
  }

  .whiteInner .left {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  .whiteInner .center {
    flex: 95%;
    height: 100%;
  }

  .whiteInner .right {
    flex: 2.5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }
}
