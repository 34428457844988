@media screen and (max-width: 600px) {
  .support {
    background-image: url(../../images/support.jpeg);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 55vh;
    width: 100%;
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 3em;
    color: white;
    font-family: "Unbounded", cursive;
  }

  .support-content {
    vertical-align: middle;
    width: auto;
    margin: auto;

    max-width: 1020px;
    padding-top: 0px 32px;
    padding-bottom: 55px;
  }
  .external-support {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 750px;

    margin: auto;
    position: inherit;
    margin-top: 2em;

    box-sizing: border-box;

    font-family: "Unbounded", cursive;

    font-size: 1em;
  }

  .external-text {
    display: flex;
    text-align: left;

    width: 100%;
    max-width: 750px;

    margin: 1em 1em 1em 1em;
    position: inherit;
    margin-top: 2em;

    font-size: 15px;
    line-height: 25px;
  }

  .support-pic {
    margin: 1em 1em 1em 1em;
  }

  .support-pic-1 {
    margin: 2em 1em 0em 1em;
  }

  .internal-support {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 750px;

    margin: auto;
    position: inherit;
    margin-top: 2em;

    box-sizing: border-box;

    font-family: "Unbounded", cursive;
    text-align: left;
    font-size: 1em;
  }

  .internal-text {
    display: flex;
    text-align: left;

    width: 100%;
    max-width: 750px;

    margin: 1em 1em 1em 1em;
    position: inherit;
    margin-top: 2em;

    font-size: 15px;
    line-height: 25px;
  }
}

@media screen and (min-width: 701px) {
  .support {
    background-image: url(../../images/support.jpeg);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 55vh;

    font-size: 7em;
    color: white;
    font-family: "Unbounded", cursive;
  }

  .support-content {
    vertical-align: middle;
    width: auto;
    margin: auto;

    max-width: 1020px;
    padding-top: 0px 32px;
    padding-bottom: 55px;
  }
  .external-support {
    display: flex;
    align-items: left;
    /* justify-content: center; */

    width: 100%;
    max-width: 750px;

    margin: auto;
    position: inherit;
    margin-top: 2em;

    box-sizing: border-box;

    font-family: "Unbounded", cursive;
    text-align: left;
    font-size: 1em;
  }

  .external-text {
    display: flex;
    text-align: left;

    width: 100%;
    max-width: 750px;

    margin: auto;
    position: inherit;
    margin-top: 2em;

    font-size: 15px;
    line-height: 25px;
  }

  .support-pic {
    margin: 1em 1em 1em 1em;
  }

  .support-pic-1 {
    margin: 2em 1em 0em 1em;
  }

  .internal-support {
    display: flex;
    align-items: left;
    /* justify-content: center; */

    width: 100%;
    max-width: 750px;

    margin: auto;
    position: inherit;
    margin-top: 2em;

    box-sizing: border-box;

    font-family: "Unbounded", cursive;
    text-align: left;
    font-size: 1em;
  }

  .internal-text {
    display: flex;
    text-align: left;

    width: 100%;
    max-width: 750px;

    margin: auto;
    position: inherit;
    margin-top: 2em;

    font-size: 15px;
    line-height: 25px;
  }

  .indent-space {
    margin-left: 0.25em;
    margin-right: 0.25em;
  }
}
